import React, { Component } from 'react'
import axios from 'axios'

let width = window.innerWidth;
let colWidth = '4'
if (width < 768) {
    colWidth = '12'
} else {
    colWidth = '4'
}

class ServicesComponent extends Component {
    constructor(props) {
        super(props)

        this.state = {
            services: [],
        }
    }


    componentDidMount() {
        axios.get(`${this.props.api}/api/collections/get/Services?token=${this.props.acctok}`)
            .then(response => {
                this.setState({ services: response.data.entries });
            })
            .catch(error => {
                console.log(error);
            })
            window.scrollTo(0, 0);        
    }    

    getServices() {
        const { services } = this.state
        const serviceList = services.map(service => (
            <div key={service._id} className={`col-${colWidth} service-item`}>
                <h2>{service.Name}</h2>
            </div>
        ))
        return serviceList
    }

    render() {
        return (
            <div className='text-center component services-component' id='services'>
                <h1>Services</h1>
                <p>
                    At Gapco we offer a wide range of quality services such as custom design apparel,
                    Printed Mask, screen printing, heat transfer printing, Embroidery, and promotional items.
                </p>
                <p>
                    Under our full Package umbrella, we employ some of the industries' most advanced screen
                    printing techniques and offer the very cutting edge with regard to inks, materials, & techniques
                    to make your next project successful and shine.
                </p>
                <div className='row no-gutters'>
                    {this.getServices()}
                </div>
                <h2 className='services-tag'>Looking for something specific? Contact us today!</h2>
            </div>
        )
    }
}

export default ServicesComponent
