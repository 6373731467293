import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class Footer extends Component {
    render() {
        return (
            <footer>
                <div className='footer row no-gutters'>
                    <div className='col-md-3 col-12'>
                        <Link to='/'>
                            <img src={require('../images/GAPCOLogoClear.png')} className='footer-logo' alt='gapco industries logo' />
                        </Link>
                    </div>
                    <div className='col-md-3 col-12'>
                        <h2 className='footer-contact'>Contact Us</h2>
                        <p>Phone: 1-868-466-7332</p>
                        <p>Email: sales.gapcott@gmail.com</p>
                        <p>Address: #5 Gaston Johnson Street</p>
                        <p>Woodbrook</p>
                        <p>Port-Of-Spain</p>
                    </div>
                    <div className='col-md-3 col-12 follow-us-footer'>
                        <h2 className='footer-contact'>Follow Us</h2>
                        <div className='footer-image-container'>
                            <a href='https://www.facebook.com/gapcoindustries2020'><img src={require('../images/fb-logo-white.png')} alt='fb gapco' /></a>
                        </div>
                        <div className='footer-image-container'>
                            <a href='https://www.instagram.com/gapcoindustries2020/?hl=en'><img src={require('../images/ig-logo-white.png')} alt='gapco industries ig' /></a>
                        </div>
                    </div>
                    <div className='col-md-3 col-12 text-center apex'>
                        <h3>Proud partner with</h3>
                        <a href='http://apexadvertisingltd.com/'><img src={require('../images/Apex-Logo-2019.png')} alt='apex advertising' /></a>
                    </div>
                    <div className='buzzolution'>
                        <a href='https://buzzolution.com/'><img src={require('../images/buzzolution.png')} alt='buzzolution consultancy' />
                            <p>Designed and Developed by Buzzolution Consultancy</p></a>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer
