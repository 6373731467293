import React, { Component } from 'react'
import { Link } from 'react-router-dom'


class MobileHeader extends Component {
    constructor(props) {
        super(props)

        this.state = {
            logoState: 'mobile-logo-full',
            hamWrapperState: 'hide-ham-wrapper',
            headerHeight: 82,
            navbarTogglerIcon: 'navbar-toggler-icon',
            handleClass: 'mobileSidebarWrapper hideMobileSidebar',
            arrowclass: 'arrow next',
            clicked: false
        }
    }

    componentDidMount() {
        document.addEventListener("scroll", () => {
            const hamWrapperState = window.scrollY <= 5 ? 'hide-ham-wrapper' : 'col-6 ham-wrapper';
            const logoState = window.scrollY <= 5 ? 'mobile-logo-full' : 'mobile-logo';
            const headerHeight = document.getElementById('mobileHeader').clientHeight
            this.setState({ logoState: logoState, hamWrapperState: hamWrapperState, headerHeight: headerHeight });

            // setTimeout(
            //     function () {                    
            //         this.setState({ hamWrapperState: hamWrapperState })                    
            //     }
            //         .bind(this),
            //     250
            // )
        });
    }

    clickHander = () => {
        if (this.state.clicked) {
            this.setState({
                handleClass: 'mobileSidebarWrapper hideMobileSidebar',
                arrowclass: 'arrow next',
                navbarTogglerIcon: 'navbar-toggler-icon',
                clicked: false
            })
        } else {
            this.setState({
                handleClass: 'mobileSidebarWrapper showMobileSidebar',
                arrowclass: 'arrow prev',
                navbarTogglerIcon: 'navbar-toggler-icon animate',
                clicked: true
            })
        }
    }

    render() {
        const { logoState, hamWrapperState, handleClass, navbarTogglerIcon } = this.state
        return (
            <>
                <div id='mobileHeader' className='mobileHeader row no-gutters'>
                    <Link to='/' className={logoState}>
                        <img src={require('../images/gapco-logo-bw-mobile.png')} className={logoState} alt='gapco logo' />
                    </Link>
                    <div className={hamWrapperState}>
                        {/* <img src={require('../images/ham.svg')} className='hamburger' onClick={this.clickHander} /> */}
                        <div className='navbar-toggler'>
                            <span className={navbarTogglerIcon} onClick={this.clickHander}></span>
                        </div>
                    </div>
                </div>
                <div className={handleClass}>
                    <div className='flex-item'>
                        <Link to='services' onClick={this.clickHander}><img src={require('../images/icons-05.svg')} alt='services' /></Link>
                        <Link to='services' onClick={this.clickHander}>Services</Link>
                    </div>
                    <div className='flex-item'>
                        <Link to='deliveries' onClick={this.clickHander}> <img src={require('../images/icons-03.svg')} alt='deliveries' /></Link>
                        <Link to='deliveries' onClick={this.clickHander}>Deliveries</Link>
                    </div>
                    <div className='flex-item'>
                        <Link to='get-a-quote' onClick={this.clickHander}> <img src={require('../images/icons-01.svg')} alt='quote' /></Link>
                        <Link to='get-a-quote' onClick={this.clickHander}>Get a quote</Link>
                    </div>
                    <div className='flex-item'>
                        <Link to='contact-us' onClick={this.clickHander}> <img src={require('../images/icons-02.svg')} alt='contacy us' /></Link>
                        <Link to='contact-us' onClick={this.clickHander}>Contact Us</Link>
                    </div>
                    <div className='flex-item'>
                        <Link to='find-us' onClick={this.clickHander}> <img src={require('../images/icons-04.svg')} alt='find us' /></Link>
                        <Link to='find-us' onClick={this.clickHander}>Find Us</Link>
                    </div>
                    <div className='flex-item'>
                        <Link to='follow-us' onClick={this.clickHander}> <img src={require('../images/icons-06.svg')} alt='follow us' /></Link>
                        <Link to='follow-us' onClick={this.clickHander}>  Follow Us</Link>
                    </div>
                </div>
            </>
        )
    }
}

export default MobileHeader
