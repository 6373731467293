import React, { Component } from 'react'

class MidBanner extends Component {
    render() {
        return (
            <div className='mid-banner'>
                <h1>You think it, We print it!</h1>
            </div>
        )
    }
}

export default MidBanner
