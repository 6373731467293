import React, { Component } from 'react'
import axios from 'axios'

class Deliveries extends Component {
    constructor(props) {
        super(props)

        this.state = {
            header: '',
            text: ''
        }
    }

    componentDidMount() {
        axios.get(`${this.props.api}/api/singletons/get/Delivery?token=${this.props.acctok}`)
            .then(response => {
                this.setState({ header: response.data.Heading, text: response.data.Content })
            })
            .catch(error => {
                console.log(error)
            })
            window.scrollTo(0, 0);
    }

    render() {
        const { header, text } = this.state
        return (
            <div className='component deliveries'>
                <h1>{header}</h1>
                <div dangerouslySetInnerHTML={{ __html: text }} />
            </div>
        )
    }
}

export default Deliveries
