import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class MenuBar extends Component {
    render() {
        return (
            <div className='row no-gutters'>
                <div className='col-3'>
                    <Link to='/'> <img src={require('../images/gapcoLogoLandscape.png')} className='img-fluid menu-logo' alt='gapco logo' /> </Link>
                </div>
                <div className='col-9 menu-list text-center'>
                    <ul>
                        <li>
                            <Link to='services'> <img src={require('../images/icons-05.svg')} alt='services' /></Link>
                            <Link to='services'>  Services </Link>
                        </li>
                        <li>
                            <Link to='deliveries'> <img src={require('../images/icons-03.svg')} alt='deliveries' /></Link>
                            <Link to='deliveries'> Deliveries </Link>
                        </li>
                        <li>
                            <Link to='get-a-quote'> <img src={require('../images/icons-01.svg')} alt='quote' /></Link>
                            <Link to='get-a-quote'> Get a quote </Link>
                        </li>
                        <li>
                            <Link to='contact-us'> <img src={require('../images/icons-02.svg')} alt='contact us' /></Link>
                            <Link to='contact-us'> Contact Us </Link>
                        </li>
                        <li>
                            <Link to='find-us'> <img src={require('../images/icons-04.svg')} alt='find us' /></Link>
                            <Link to='find-us'> Find Us </Link>
                        </li>
                        <li>
                            <Link to='follow-us'> <img src={require('../images/icons-06.svg')} alt='follow us' /></Link>
                            <Link to='follow-us'> Follow Us </Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default MenuBar
