import React, { Component } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import MobileHome from './MobileHome'
import DesktopHome from './DesktopHome';
import MobileHeader from './MobileHeader';
import Header from './Header';
import Footer from './Footer';
import ServicesComponent from './ServicesComponent'
import Deliveries from './Deliveries'
import Quotations from './Quotations';
import ContactUs from './ContactUs';
import FindUs from './FindUs';
import FollowUs from './FollowUs';
import TabletHeader from './TabletHeader';
import TabletHome from './TabletHome';
import NotFound from './NotFound'
import '../css/home.css'



class Home extends Component {
    constructor(props) {
        super(props)
        let width = window.innerWidth;
        this.state = {
            api: 'https://gapcocms.apexadvertisingltd.com',
            acctok: '80fabd0f732f0ea42fac300218bfe7'
        }
        if (width < 768) {
            this.state.renderComponent = (
                <Router>
                    <div className="container-fluid app-container">
                        <MobileHeader {...this.state} />
                        <Switch>
                            <Route exact path='/' render={(props) => <MobileHome {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/services' render={(props) => <ServicesComponent {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/deliveries' render={(props) => <Deliveries {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/get-a-quote' render={(props) => <Quotations {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/contact-us' render={(props) => <ContactUs {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/find-us' render={(props) => <FindUs {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/follow-us' render={(props) => <FollowUs {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/not-found' component={NotFound} />
                            <Route component={NotFound} />
                        </Switch>
                        <Footer {...this.state} />
                    </div>
                </Router>
            );
        }
        else if (width >= 768 && width <= 992) {
            this.state.renderComponent = (
                <Router>
                    <div className="container-fluid app-container">
                        <TabletHeader {...this.state} />
                        <Switch>
                            <Route exact path='/' render={(props) => <TabletHome {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/services' render={(props) => <ServicesComponent {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/deliveries' render={(props) => <Deliveries {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/get-a-quote' render={(props) => <Quotations {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/contact-us' render={(props) => <ContactUs {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/find-us' render={(props) => <FindUs {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/follow-us' render={(props) => <FollowUs {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/not-found' component={NotFound} />
                            <Route component={NotFound} />
                        </Switch>
                        <Footer />
                    </div>
                </Router>
            );
        } else if (width > 992) {
            this.state.renderComponent = (
                <Router>
                    <div className="container-fluid app-container">
                        <Header {...this.state} />
                        <Switch>
                            <Route exact path='/' render={(props) => <DesktopHome {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/services' render={(props) => <ServicesComponent {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/deliveries' render={(props) => <Deliveries {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/get-a-quote' render={(props) => <Quotations {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/contact-us' render={(props) => <ContactUs {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/find-us' render={(props) => <FindUs {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/follow-us' render={(props) => <FollowUs {...props} api={this.state.api} acctok={this.state.acctok} />} />
                            <Route exact path='/not-found' component={NotFound} />
                            <Route component={NotFound} />
                        </Switch>
                        <Footer />
                    </div>
                </Router>
            );
        }
    }

    render() {
        return this.state.renderComponent;
    }
}

export default Home