import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'

class BrandedItems extends Component {
    constructor(props) {
        super(props)

        this.state = {
            products: [],
        }
    }

    componentDidMount() {
        axios.get(`${this.props.api}/api/collections/get/Products?token=${this.props.acctok}`)
            .then(response => {
                this.setState({ products: response.data.entries })
            })
            .catch(error => {
                console.log(error);
            })
    }

    BrandedItems() {
        const { products } = this.state
        const brandedItems = products.map(product => (
            <div key={product._id} className='col-4 branded-item'>
                <img src={`${this.props.api}/${product.DesktopImage.path}`} className='img-fluid' alt={product.Name} />
                <h3>{product.Name}</h3>
            </div>
        ))
        return brandedItems
    }

    render() {
        return (
            <div className='container-fluid'>
                <div className='branded-items row text-center'>
                    {this.BrandedItems()}
                    <div className='col-12 text-center'>
                        <Link to='services'>
                            <input className='btn view-all-branded-btn' value='View All' />
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default BrandedItems