import React, { Component } from 'react'
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import axios from 'axios'


class ContactUs extends Component {
    constructor(props) {
        super(props)

        this.state = {
            form: {
                name: '',
                email: '',
                subject: ''
            },
            submitted: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onChange = (event) => {
        const { form } = this.state;
        form[event.target.name] = event.target.value;
        this.setState({ form });
    }

    // submit = () => {
    //     this.form.submit();
    // }

    submit = () => {        
        this.form.submit();      
    }

    handleSubmit = () => {
        this.setState({ submitted: true }, () => {
            let formdata = new FormData()
            formdata.set('name', this.state.form.name)
            formdata.set('email', this.state.form.email)
            formdata.set('subject', this.state.form.subject)
            axios.post(`https://cms.gapcott.com/postman.php`, formdata)                
                .catch(error => {
                    console.log(error);
                })
            axios.post(`https://cms.gapcott.com/postcontact.php`, this.state)               
                .catch(error => {
                    console.log(error);
                })
        })
    }

    render() {
        const { form } = this.state
        return (
            <div className='component contact-us-comp'>
                <script src="https://www.google.com/recaptcha/api.js"></script>
                <h1>Contact Us</h1>
                <ValidatorForm ref={(r) => { this.form = r }} onChange={this.formChange} onSubmit={this.handleSubmit} id='contact-form' instantValidate>
                    <div className='row no-gutters'>
                        <div className='col-lg-8 col-12 contact-form text-center'>
                            <TextValidator
                                name='name'
                                label='Name'
                                validators={['required']}
                                errorMessages={['please enter your name']}
                                value={form.name}
                                onChange={this.onChange}
                                validatorListener={this.validatorListener}
                                data-style='validatorStyle'
                                fullWidth                                
                            />
                            <TextValidator
                                name="email"
                                label="Email"
                                validators={['required', 'isEmail']}
                                errorMessages={['please enter your email address', 'please enter a valid email']}
                                value={form.email}
                                onChange={this.onChange}
                                validatorListener={this.validatorListener}
                                fullWidth
                                data-style='validatorStyle'
                            />
                            <TextValidator
                                type='select'
                                name='subject'
                                label={`Subject`}
                                validators={['required']}
                                errorMessages={['please enter a subject']}
                                value={form.subject}
                                onChange={this.onChange}
                                validatorListener={this.validatorListener}
                                fullWidth
                                data-style='validatorStyle'
                            />
                        </div>
                        <div className="col-12 text-center">
                            <input type='button' value={(this.state.submitted && 'SUBMITTED!') || ('SUBMIT')} onClick={this.submit} disabled={(this.state.submitted && true)} className='btn btn-submit' />
                        </div>
                    </div>
                </ValidatorForm>
            </div>
        )
    }
}

export default ContactUs
