import React, { Component } from 'react'
import Slider from './Slider'
import MenuInfoBar from './MenuInfoBar'

class HeaderSlider extends Component {
    render() {
        return (
            <>
                <Slider {...this.props} />
                <MenuInfoBar />
            </>
        )
    }
}

export default HeaderSlider
