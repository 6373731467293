import React, { Component } from 'react'
import MenuBar from './MenuBar'
import Slider from './Slider'
import MenuInfoBar from './MenuInfoBar'

class TabletHeader extends Component {
    render() {
        return (
            <>
              <MenuBar />
              <Slider {...this.props} />
              <MenuInfoBar />  
            </>
        )
    }
}

export default TabletHeader