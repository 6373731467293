import React, { Component } from 'react'

class FollowUs extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className='component social-media'>
                <h1>Follow Us</h1>
                <p>Follow us on Facebook and Instagram for more updates!</p>
                <div className='row no-gutters text-center'>
                    <div className='col-md-6 col-12 soc-med-container fb-container'>
                        <a href='https://www.facebook.com/gapcoindustries2020'><img src={require('../images/fb-logo-white.png')} alt='fb gapco link' /></a>
                    </div>
                    <div className='col-md-6 col-12 soc-med-container ig-container'>
                        <a href='https://www.instagram.com/gapcoindustries2020/?hl=en'><img src={require('../images/ig-logo-white.png')} alt='ig gapco link' /></a>
                    </div>
                </div>
            </div>
        )
    }
}

export default FollowUs
