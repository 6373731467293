import React, { Component } from 'react'

class Welcome extends Component {
    render() {
        return (
            <div className='text-center welcome-comp'>
                <h1>Welcome To Gapco</h1>
                <p>
                    Gapco brings your ideas into physical reality "You think it -- We print it".
                    We're a full in house apparel and printing company providing you with quality
                    products, impeccable service and on time delivery with competitive prices.
                </p>
            </div>
        )
    }
}

export default Welcome
