import React, { Component } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { Link } from 'react-router-dom'
import axios from 'axios'

const getConfigurableProps = {
    showArrows: false,
    showStatus: false,
    showIndicators: false,
    infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: false,
    autoPlay: true,
    stopOnHover: false,
    swipeable: true,
    dynamicHeight: false,
    emulateTouch: false,
    // thumbWidth: ,
    // selectedItem: 0,
    interval: 3000,
    transitionTime: 350,
    swipeScrollTolerance: 5,
}

class MobileSlider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            slider: [],
        }
    }

    componentDidMount() {
        axios.get(`${this.props.api}/api/collections/get/ProductsSlider?token=${this.props.acctok}`)
            .then(response => {
                this.setState({ slider: response.data.entries })
            })
            .catch(error => {
                console.log(error);
            })
    }

    sliderBanner() {
        const { slider } = this.state
        const productSlider = slider.map(slider => (
            <div key={slider._id}>
                <img src={`${this.props.api}/${slider.MobileImage.path}`} className='menu-img' alt={slider._id} />
                <div className='action-box text-center'>
                    <div dangerouslySetInnerHTML={{ __html: slider.Name }} />
                    <Link to='services'><input type="button" className="btn action-btn" value='Learn More' /></Link>
                </div>
            </div>
        ))
        return productSlider
    }


    render() {
        const { slider } = this.state
        return (
            <div className='slider-div'>
                {(slider && slider.length > 0) && (
                    <Carousel {...getConfigurableProps}>
                        {this.sliderBanner()}
                    </Carousel>
                )}
            </div>            
        )
    }
}

export default MobileSlider