import React, { Component } from 'react'
import Welcome from './Welcome'
import BrandedItems from './BrandedItems'
import MidBanner from './MidBanner'
import Services from './Services'
import HeaderSlider from './HeaderSlider'

class DesktopHome extends Component {   
    componentDidMount() {
        window.scrollTo(0, 0);
    } 
    render() {
        return (
            <>
                <HeaderSlider {...this.props} />
                <Welcome />
                <BrandedItems  {...this.props}/>
                <MidBanner />
                <Services {...this.props} />
            </>
        )
    }
}

export default DesktopHome
