import React, { Component } from 'react'

class FindUs extends Component {
    
    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <div className='component'>
                <h1>Find Us</h1>                
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3920.9967680704212!2d-61.521439485200254!3d10.65735809240241!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8c36095fe22b1769%3A0x350e21a9cec959aa!2sGapco%20Industries!5e0!3m2!1sen!2stt!4v1608075967414!5m2!1sen!2stt" width="100%" height="600" frameborder="0" allowfullscreen="" aria-hidden="false" title='gapco' tabindex="0"></iframe>                
            </div>
        )
    }
}

export default FindUs
