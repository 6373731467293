import React, { Component } from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import axios from 'axios'

const getConfigurableProps = {
    showArrows: true,
    showStatus: false,
    showIndicators: true,
    infiniteLoop: true,
    showThumbs: false,
    useKeyboardArrows: false,
    autoPlay: false,
    stopOnHover: false,
    swipeable: true,
    dynamicHeight: false,
    emulateTouch: false,
    // thumbWidth: ,
    // selectedItem: 0,
    interval: 3000,
    transitionTime: 350,
    swipeScrollTolerance: 5,
}

class MobileProdSlider extends Component {
    constructor(props) {
        super(props)

        this.state = {
            products: [],
        }
    }

    componentDidMount() {
        axios.get(`${this.props.api}/api/collections/get/Products?token=${this.props.acctok}`)
            .then(response => {
                this.setState({ products: response.data.entries })
            })
            .catch(error => {
                console.log(error);
            })
    }

    BrandedItems() {
        const { products } = this.state
        const brandedItems = products.map(product => (
            <>
                <img key={product._id} src={`${this.props.api}/${product.DesktopImage.path}`} className='img-fluid' alt={`poroduct ${product.Name}`} />
                <h3>{product.Name}</h3>
            </>
        ))
        return brandedItems
    }

    render() {
        const { products } = this.state
        return (
            <div className='mobile-branded-products text-center'>
                {(products && products.length > 0) && (
                    <Carousel {...getConfigurableProps}>
                        {this.BrandedItems()}
                    </Carousel>
                )}
            </div>
            // <div className='mobile-branded-products text-center'>
            //     <img src={require('../images/apparel 2.png')} />
            // </div>
        )
    }
}

export default MobileProdSlider