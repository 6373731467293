import React, { Component } from 'react'
import axios from 'axios'

let width = window.innerWidth;
let colWidth = '4'
if (width < 768) {
    colWidth = '12'
} else {
    colWidth = '4'
}

class Services extends Component {
    constructor(props) {
        super(props)

        this.state = {
            services: [],
        }
    }

    componentDidMount() {
        axios.get(`${this.props.api}/api/collections/get/Services?token=${this.props.acctok}`)
            .then(response => {
                this.setState({ services: response.data.entries });
            })
            .catch(error => {
                console.log(error);
            })
    }

    getServices() {
        const { services } = this.state
        const serviceList = services.map(service => (
            <div key={service._id} className={`col-${colWidth} service-item`}>
                <h2>{service.Name}</h2>
            </div>
        ))
        return serviceList
    }

    render() {
        return (
            <div className='services text-center' id='services'>
                <h1>We Provide</h1>
                <div className='row no-gutters'>
                    {this.getServices()}                    
                </div>
                <h2 className='services-tag'>Looking for something specific? Contact us today!</h2>
            </div>
        )
    }
}

export default Services
