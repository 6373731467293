import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class NotFound extends Component {
    render() {
        return (
            <div className='component not-found row no-gutters text-center align-items-center'>
                <div className='col-12 col-md-6'>
                    <img src={require('../images/notfound.png')} alt='not found' />
                </div>
                <div className='col-12 col-md-6'>
                    <p>
                        Sorry, we couldn't find what you were looking for. How about we go <Link to='/'>home</Link>?
                    </p>
                </div>
            </div>
        )
    }
}

export default NotFound
