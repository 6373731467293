import React, { Component } from 'react'

class MenuInfoBar extends Component {
    render() {
        return (
            <div className='menu-info-bar d-flex justify-content-end'>
                <p>Phone: 1-868-466-7332</p>
                <p>Email: sales.gapcott@gmail.com</p>
                <a href='https://www.facebook.com/gapcoindustries2020'><img src={require('../images/fb-logo-white.png')} width='18px' height='18px' alt='fb gapco' /></a>
                <a href='https://www.instagram.com/gapcoindustries2020/?hl=en'><img src={require('../images/ig-logo-white.png')} width='18px' height='18px' alt='ig gapco' /></a>
            </div>
        )
    }
}

export default MenuInfoBar
