import React, { Component } from 'react'
import MobileSlider from './MobileSlider'
import Welcome from './Welcome'
import MobileProdSlider from './MobileProdSlider'
import Midbanner from './MidBanner'
import Services from './Services'

export class MobileHome extends Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <>
                <MobileSlider {...this.props} />
                <Welcome {...this.props} />
                <MobileProdSlider {...this.props} />
                <Midbanner />
                <Services {...this.props} />
            </>
        )
    }
}

export default MobileHome
